import classNames from 'classnames';

import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';
import { NewsletterForm } from '@components/newsletterForm/NewsletterForm';

import { HomePageTeaserSectionRecord } from '@interfaces';

import styles from './home-page-teaser-section.module.scss';

type HomePageTeaserSectionProps = HomePageTeaserSectionRecord;

export const HomePageTeaserSection = ({
	backgroundColor,
	clippedImage,
	newsletterForm,
	isAlternate,
	text,
	title,
	hash,
}: HomePageTeaserSectionProps) => {
	const style = backgroundColor
		? ({ '--background-color': backgroundColor.hex } as React.CSSProperties)
		: undefined;

	return (
		<div
			className={classNames(styles['home-page-teaser-section'], {
				[styles['home-page-teaser-section--is-alternate']]: isAlternate,
			})}
			id={hash}
			style={style}
		>
			<div className={styles['home-page-teaser-section__content']}>
				<h2 className={styles['home-page-teaser-section__heading']}>{title}</h2>
				<Markdown
					className={styles['home-page-teaser-section__text']}
					text={text}
				/>
				{newsletterForm ? (
					<NewsletterForm
						className={styles['home-page-teaser-section__form']}
						location="website home page"
						{...newsletterForm}
					/>
				) : null}
			</div>
			<ClippedImage
				className={styles['home-page-teaser-section__image']}
				{...clippedImage}
				sizes="(min-width: 56.25em) 52vw, 100vw"
			/>
		</div>
	);
};
