import { useRef } from 'react';
import classNames from 'classnames';

import { useMarquee } from '@hooks/useMarquee';
import { LogoSectionRecord } from '@interfaces';

import styles from './logo-marquee.module.scss';

type LogoMarqueeProps = LogoSectionRecord;

export const LogoMarquee = ({ title, logos }: LogoMarqueeProps) => {
	const marqueeElementRef = useRef<HTMLDivElement>(null);

	const { isAnimated } = useMarquee(marqueeElementRef);

	const totalLogoWidth = logos.reduce((accumulator, { width }) => {
		return accumulator + width;
	}, 0);

	const marqueeStyle = {
		'--marquee-total-logo-width': totalLogoWidth,
	} as React.CSSProperties;

	return (
		<section
			aria-label={title}
			className={classNames(styles.marquee, {
				[styles['is-animated']]: isAnimated,
			})}
			ref={marqueeElementRef}
			style={isAnimated ? marqueeStyle : undefined}
		>
			<div className={styles.logos}>
				{logos.map(({ id, alt, url, width, height }) => {
					const logoStyle = {
						'--marquee-logo-aspect-ratio': width / height,
					} as React.CSSProperties;

					return (
						<img
							alt={alt}
							className={styles.logo}
							height={height}
							key={id}
							src={url}
							style={logoStyle}
							width={width}
						/>
					);
				})}
			</div>
		</section>
	);
};
