import classNames from 'classnames';

import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';

import { PressQuoteRecord } from '@interfaces';

import styles from './press-quote.module.scss';

interface PressQuoteProps extends PressQuoteRecord {
	className?: string;
	sizes?: string;
}

export const PressQuote = ({
	quote,
	source,
	link,
	clippedImage,
	className,
	sizes = '100vw',
}: PressQuoteProps) => {
	return (
		<article className={classNames(className, styles.wrapper)}>
			<ClippedImage sizes={sizes} {...clippedImage} />
			<figure className={styles.quote}>
				<blockquote>
					<Markdown text={quote} />
				</blockquote>
				<figcaption className={styles.source}>
					{link ? (
						<a className="anchor anchor--external" href={link}>
							{source}
						</a>
					) : (
						source
					)}
				</figcaption>
			</figure>
		</article>
	);
};
