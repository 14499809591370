import classNames from 'classnames';

import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';

import { InfoSectionRecord } from '@interfaces';

import styles from './info-section.module.scss';

interface InfoSectionProps extends InfoSectionRecord {
	isHeadingVisible?: boolean;
}

export const InfoSection = ({
	isAlternate,
	title,
	hash,
	text,
	clippedImage,
	isHeadingVisible = true,
}: InfoSectionProps) => {
	return (
		<div
			className={classNames(styles['info-section'], {
				[styles['info-section--is-alternate']]: isAlternate,
			})}
			id={hash}
		>
			<div className={styles['info-section__info']}>
				{isHeadingVisible ? (
					<h2 className={styles['info-section__heading']}>{title}</h2>
				) : null}
				<Markdown className={styles['info-section__text']} text={text} />
			</div>
			<ClippedImage
				className={styles['info-section__image']}
				{...clippedImage}
				sizes="(min-width: 56.25em) 52vw, 100vw"
			/>
		</div>
	);
};
