import { useRef } from 'react';
import classNames from 'classnames';

import { useMarquee } from '@hooks/useMarquee';

import styles from './node-marquee.module.scss';

interface NodeMarqueeProps {
	className?: string;
	children: React.ReactNode;
	characterCount: number;
}

export const NodeMarquee = ({
	className,
	children,
	characterCount,
}: NodeMarqueeProps) => {
	const marqueeElementRef = useRef<HTMLDivElement>(null);
	const { isAnimated } = useMarquee(marqueeElementRef);

	const style = {
		'--marquee-text-character-count': characterCount,
	} as React.CSSProperties;

	return (
		<div
			className={classNames(className, styles.marquee, {
				[styles['is-animated']]: isAnimated,
			})}
			ref={marqueeElementRef}
			style={isAnimated ? style : undefined}
		>
			<div className={styles['children-wrapper']}>{children}</div>
		</div>
	);
};
