import React from 'react';
import classNames from 'classnames';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
}

export const Button = ({
	children,
	className,
	type,
	...buttonProps
}: ButtonProps) => {
	return (
		<button
			className={classNames(className, 'button')}
			type={type === 'submit' ? 'submit' : 'button'}
			{...buttonProps}
		>
			{children}
		</button>
	);
};
