import { Svg } from '@components/svg/Svg';

import { JobPositionRecord } from '@interfaces';

import styles from './job-position.module.scss';

type JobPositionProps = JobPositionRecord;

export const JobPosition = ({ role, description, url }: JobPositionProps) => {
	return (
		<article className={styles['job-position']}>
			<header className={styles['job-position__header']}>
				<h3 className={styles['job-position__role']}>
					<a className="breakout" href={url}>
						{role}
					</a>
				</h3>
				<Svg
					className={styles['job-position__arrow']}
					id="arrow-serial-output"
				/>
			</header>
			<p className={styles['job-position__description']}>{description}</p>
		</article>
	);
};
