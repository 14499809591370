import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useReCaptcha } from 'next-recaptcha-v3';
import { captureException } from '@sentry/nextjs';
import classNames from 'classnames';

import { Button } from '@components/button/Button';
import { FormInput } from '@components/formInput/FormInput';
import { FormSelect } from '@components/formSelect/FormSelect';
import { FormTextarea } from '@components/formTextarea/FormTextarea';
import { Markdown } from '@components/markdown/Markdown';
import { SplitHeading } from '@components/splitHeading/SplitHeading';

import { neverError } from '@common/utils/neverError';
import { CONTACT_FORM_DEFAULT_ERROR_MESSAGE } from '@data/constants';
import {
	ContactFormRecord,
	FormField,
	FormInputRecord,
	FormSelectRecord,
	FormTextareaRecord,
} from '@interfaces';

import styles from './contact-form.module.scss';

const renderFormField = (formField: FormField) => {
	const { id: key } = formField;
	const typeNameKey = '__typename';

	switch (formField[typeNameKey]) {
		case 'FormInputRecord':
			return <FormInput key={key} {...(formField as FormInputRecord)} />;
		case 'FormTextareaRecord':
			return <FormTextarea key={key} {...(formField as FormTextareaRecord)} />;
		case 'FormSelectRecord':
			return <FormSelect key={key} {...(formField as FormSelectRecord)} />;
		case undefined:
			// new form fields in dato return as empty objects
			// we don’t want to display an error in that case
			return null;
		default:
			throw neverError('Unknown form field', formField);
	}
};

interface ContactFormProps extends ContactFormRecord {
	className?: string;
}

export const ContactForm = ({
	className,
	title,
	text,
	formName,
	formFields,
	submitButtonLabel,
	thankYouMessage,
}: ContactFormProps) => {
	const [isFormValid, setIsFormValid] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		CONTACT_FORM_DEFAULT_ERROR_MESSAGE,
	);
	const [didFormSubmissionFail, setDidFormSubmissionFail] = useState(false);
	const { executeRecaptcha } = useReCaptcha();
	const router = useRouter();

	const onChange = (event: React.FormEvent<HTMLFormElement>) => {
		setIsFormValid(event.currentTarget.checkValidity());
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsInProgress(true);
		setDidFormSubmissionFail(false);

		if (!isFormValid) {
			return;
		}

		if (window.clarity) {
			window.clarity('event', 'submitContactForm');
		}

		const { action: endpoint } = event.target as HTMLFormElement;

		const formElement = event.currentTarget;
		const formData = new FormData(formElement);
		const data = Object.fromEntries(
			formFields.map((formField) => [
				formField.name,
				formData.get(formField.name),
			]),
		);
		try {
			const recaptchaToken = await executeRecaptcha('form_submit');

			const options = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					data,
					recaptchaToken,
				}),
			};
			const response = await fetch(endpoint, options);
			const result = await response.json();

			if (result.ok) {
				router.push(`/thank-you/${thankYouMessage.identifier}`);
			} else {
				setErrorMessage(result.message);
				setDidFormSubmissionFail(true);
				setIsInProgress(false);
			}
		} catch (e) {
			captureException(e);
			setErrorMessage(CONTACT_FORM_DEFAULT_ERROR_MESSAGE);
			setDidFormSubmissionFail(true);
			setIsInProgress(false);
		}
	};

	return (
		<div
			className={classNames(className, styles['contact-form'])}
			id={formName}
		>
			<div className={styles.introduction}>
				<SplitHeading className={styles.title} heading={title} tag="h2" />
				<Markdown className={styles.text} text={text} />
			</div>
			<form
				action={`/api/contact-form/${formName}`}
				className={styles.form}
				method="POST"
				name={formName}
				onChange={onChange}
				onSubmit={onSubmit}
			>
				<fieldset className={styles.fieldset} disabled={isInProgress}>
					{formFields.map((formField) => {
						return renderFormField(formField);
					})}
					<p className="font-t7">
						Please don’t submit any urls: these messages will be considered
						spam. This site is protected by reCAPTCHA and the Google{' '}
						<a className="anchor" href="https://policies.google.com/privacy">
							Privacy Policy
						</a>{' '}
						and{' '}
						<a className="anchor" href="https://policies.google.com/terms">
							Terms of Service
						</a>{' '}
						apply.
					</p>
					{didFormSubmissionFail ? <div>{errorMessage}</div> : null}
					<Button disabled={!isFormValid || isInProgress} type="submit">
						{submitButtonLabel}
					</Button>
				</fieldset>
			</form>
		</div>
	);
};
