import classNames from 'classnames';

import { CallToActionButton } from '@components/callToAction/CallToActionButton';
import { Countdown } from '@components/countdown/Countdown';
import { Markdown } from '@components/markdown/Markdown';
import { NewsletterInline } from '@components/newsletterInline/NewsletterInline';
import { NodeMarquee } from '@components/nodeMarquee/NodeMarquee';
import { Video } from '@components/video/Video';

import { catchOrphanedWords } from '@common/utils/string';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { CoverSectionRecord } from '@interfaces';

import styles from './cover-section.module.scss';

type CoverSectionProps = CoverSectionRecord;

const TICKER_DIVIDER_CHAR_COUNT = 3;
const MAX_LARGE_TITLE_CHAR_COUNT = 25;

export const CoverSection = ({
	textColor,
	backgroundColor,
	title,
	titleSize,
	text,
	tickerText,
	media,
	countdown,
	cta,
	isFullBleedMedia,
	newsletter,
}: CoverSectionProps) => {
	const isAssetFill = media && isFullBleedMedia;
	const isLargerScreen = useMediaQuery('(min-width: 56.25em)');
	const isLightText = textColor === 'Light';
	const ctaButton = cta?.length ? cta[0] : null;
	const style = backgroundColor
		? ({
				'--background-color': backgroundColor.hex,
			} as React.CSSProperties)
		: undefined;

	const renderMarquee = tickerText ? (
		<NodeMarquee
			characterCount={TICKER_DIVIDER_CHAR_COUNT + tickerText.length}
			className={styles.marquee}
		>
			<>
				&emsp;
				<span className={styles['ticker-divider']}>⚘</span>
				&emsp;{tickerText}
			</>
		</NodeMarquee>
	) : null;

	const getPrimaryFontClass = () => {
		if (titleSize !== 'Auto') {
			return `font-${titleSize?.toLowerCase()}`;
		} else if (media && title.length > MAX_LARGE_TITLE_CHAR_COUNT) {
			return 'font-t2';
		}

		return 'font-t1';
	};

	const primaryFontClass = getPrimaryFontClass();

	return (
		<section
			className={classNames(styles.cover, {
				[styles['is-fill']]: isAssetFill,
				[styles['is-light-text']]: isLightText,
			})}
			style={style}
		>
			<div>
				<div
					className={classNames(styles.main, {
						[styles['is-fill']]: isAssetFill,
						[styles['is-light-text']]: isLightText,
					})}
				>
					{media && (
						<div
							className={classNames(styles.media, {
								[styles['is-fill']]: isAssetFill,
							})}
						>
							{media.video ? (
								<Video
									hasAutoPlay
									hasControls={false}
									isLoop
									isMuted
									media={media}
								/>
							) : (
								<img
									alt={media.alt || ''}
									height={isAssetFill ? '100%' : media.height}
									loading="lazy"
									sizes="(min-width: 37.5em) 50vw, 100vw"
									src={media.responsiveImage.src}
									srcSet={media.responsiveImage.srcSet}
									width={isAssetFill ? 'auto' : media.width}
								/>
							)}
						</div>
					)}

					<div
						className={classNames(styles.content, {
							[styles['is-fill']]: isAssetFill,
							[styles['is-text-only']]: !media,
							[styles['has-ticker']]: !!tickerText,
						})}
					>
						<div
							className={classNames(styles['title-section'], {
								[styles['is-text-only']]: !media,
							})}
						>
							{!isLargerScreen && renderMarquee}
							<h2
								className={primaryFontClass}
								dangerouslySetInnerHTML={{
									__html: catchOrphanedWords(title, 2),
								}}
							/>
						</div>
						<div
							className={classNames(styles['addition-info'], {
								[styles['is-hidden']]: !text && !ctaButton && !newsletter,
							})}
						>
							{text ? (
								<Markdown
									className={styles['info-section__text']}
									text={text}
								/>
							) : null}
							{countdown && (
								<Countdown className={primaryFontClass} to={countdown} />
							)}
							{ctaButton ? (
								<CallToActionButton
									className={styles['cta-button']}
									{...ctaButton}
								/>
							) : null}
							{newsletter ? (
								<NewsletterInline
									className={styles.newsletter}
									form={newsletter}
									location="website page cover section"
								/>
							) : null}
						</div>
						{isLargerScreen && isFullBleedMedia && renderMarquee}
					</div>
				</div>
			</div>
			{isLargerScreen && !isFullBleedMedia && renderMarquee}
		</section>
	);
};
