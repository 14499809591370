import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';

import { CallToActionRecord } from '@interfaces';

import styles from './call-to-action.module.scss';
import { CallToActionButton } from './CallToActionButton';
import { CallToActionLink } from './CallToActionLink';

type CallToActionProps = CallToActionRecord;

export const CallToAction = ({
	title,
	text,
	callToActionLink,
	showSimpleButton,
}: CallToActionProps) => {
	return (
		<div
			className={classNames(styles['call-to-action'], {
				[styles['call-to-action--show-simple-button']]: showSimpleButton,
			})}
		>
			{showSimpleButton ? (
				<CallToActionButton {...callToActionLink[0]} />
			) : (
				<>
					<div className={styles['call-to-action__content']}>
						<h2 className={styles['call-to-action__heading']}>{title}</h2>
						<Markdown className={styles['call-to-action__text']} text={text} />
					</div>
					<CallToActionLink
						className={styles['call-to-action__link']}
						{...callToActionLink[0]}
					/>
				</>
			)}
		</div>
	);
};
