import { useRef, useState } from 'react';
import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';

import { ShowcaseItemRecord } from '@interfaces';

import styles from './showcase-item.module.scss';

interface ShowcaseItemProps extends ShowcaseItemRecord {
	className?: string;
	isInitiallyExpanded?: boolean;
}

export const ShowcaseItem = ({
	id,
	heading,
	subheading,
	image,
	text,
	className,
	isInitiallyExpanded = false,
}: ShowcaseItemProps) => {
	const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
	const showcaseItemElementRef = useRef<HTMLElement>(null);

	const { responsiveImage } = image;

	const onClick = () => {
		const showcaseItemElement = showcaseItemElementRef.current;

		if (showcaseItemElement) {
			setIsExpanded(!isExpanded);
		}
	};

	return (
		<article
			className={classNames(className, styles.item, {
				[styles['is-expanded']]: isExpanded,
			})}
			key={id}
			ref={showcaseItemElementRef}
			tabIndex={-1}
		>
			<header className={styles.header}>
				<h3>
					<button
						aria-controls={`panel-${id}`}
						aria-expanded={isExpanded}
						className={styles.button}
						id={`button-${id}`}
						onClick={onClick}
						type="button"
					>
						{heading}
					</button>
				</h3>
				<p className={styles.subheading}>{subheading}</p>
			</header>
			<div className={styles['image-wrapper']}>
				<img
					alt={image.alt || ''}
					className={styles.image}
					height={image.height}
					loading="lazy"
					sizes="(min-width: 56.25em) 30vw, 100vw"
					src={responsiveImage.src}
					srcSet={responsiveImage.srcSet}
					width={image.width}
				/>
			</div>
			<div
				aria-hidden={!isExpanded}
				aria-labelledby={`button-${id}`}
				className={styles.panel}
				id={`panel-${id}`}
				role="region"
			>
				<img
					alt={image.alt || ''}
					className={styles['panel-image']}
					height={image.height}
					loading="lazy"
					sizes="100vw"
					src={responsiveImage.src}
					srcSet={responsiveImage.srcSet}
					width={image.width}
				/>
				<Markdown className={styles.text} text={text} />
			</div>
		</article>
	);
};
