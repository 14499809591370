import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { CallToActionLinkRecord } from '@interfaces';

import styles from './call-to-action-link.module.scss';

interface CallToActionLinkProps
	extends Omit<CallToActionLinkRecord, '__typename' | 'id' | 'textColor'> {
	className?: string;
}

export const CallToActionLink = ({
	label,
	url,
	className,
}: CallToActionLinkProps) => {
	return (
		<a
			className={classNames(className, styles['call-to-action-link'])}
			href={url}
		>
			<div className={styles['call-to-action-link__wrapper']}>
				<p className={styles['call-to-action-link__label']}>{label}</p>
				<Svg
					className={styles['call-to-action-link__arrow']}
					id="call-to-action-arrow"
				/>
			</div>
		</a>
	);
};
