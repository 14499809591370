import { useState } from 'react';
import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';
import { NewsletterApiForm } from '@components/newsletterApiForm/NewsletterApiForm';

import { handleError } from '@common/utils/handleError';
import { getMailchimpUrl } from '@common/utils/mailchimp';
import {
	ApiSubscribeErrorResponse,
	ApiSubscribeSuccessResponse,
	NewsletterFormRecord,
	NewsletterSignUpLocation,
} from '@interfaces';

import styles from './newsletter-inline.module.scss';

interface NewsletterSectionProps {
	className?: string;
	location: NewsletterSignUpLocation;
	form: NewsletterFormRecord;
}

export const NewsletterInline = ({
	className,
	form,
	location,
}: NewsletterSectionProps) => {
	const {
		mailchimpListId,
		mailchimpFormLocationFieldTag,
		thankYouMessage,
		mailchimpTags,
	} = form;
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const onSuccess = ({ status, email }: ApiSubscribeSuccessResponse) => {
		if (status === 'subscribed') {
			setErrorMessage(null);
			setIsSuccessfullySubmitted(true);
		} else {
			setIsSuccessfullySubmitted(false);
			setErrorMessage(
				`We can’t subscribe you, please try our [Mailchimp form](${getMailchimpUrl(
					{
						mailchimpListId,
						email,
					},
				)})`,
			);
		}
	};

	const onError = ({ error }: ApiSubscribeErrorResponse) => {
		handleError(error);
		setErrorMessage('We’re sorry, something went wrong...');
	};

	return (
		<div className={classNames(styles['newsletter-inline'], className)}>
			{isSuccessfullySubmitted ? (
				<>
					<h2 className="font-t3-title">{thankYouMessage.title}</h2>
					<Markdown text={thankYouMessage.text} />
				</>
			) : (
				<>
					<NewsletterApiForm
						className={styles.form}
						listId={mailchimpListId}
						location={location}
						locationFieldTag={mailchimpFormLocationFieldTag}
						mailchimpTags={mailchimpTags}
						onError={onError}
						onSuccess={onSuccess}
					/>
					{errorMessage ? (
						<Markdown className={styles.error} text={errorMessage} />
					) : null}
				</>
			)}
		</div>
	);
};
