import Link from 'next/link';
import classNames from 'classnames';

import { CallToActionLinkRecord } from '@interfaces';

import styles from './call-to-action-button.module.scss';

interface CallToActionButtonProps
	extends Omit<CallToActionLinkRecord, '__typename' | 'id'> {
	className?: string;
}

export const CallToActionButton = ({
	textColor,
	url,
	label,
	backgroundColor,
	smallPrint,
	className,
}: CallToActionButtonProps) => {
	const style = backgroundColor
		? ({
				'--cta-background-color': backgroundColor?.hex,
			} as React.CSSProperties)
		: undefined;

	return (
		<>
			<Link
				className={classNames('button', styles.button, className, {
					[styles[`is-${textColor.toLocaleLowerCase()}-text`]]:
						textColor !== 'Default',
					[styles['has-bg-color']]: backgroundColor,
				})}
				href={url}
				style={style}
			>
				{label}
			</Link>
			{smallPrint && <p className={styles['small-print']}>{smallPrint}</p>}
		</>
	);
};
