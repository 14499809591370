import { useState } from 'react';

import { Svg } from '@components/svg/Svg';

import { FormSelectRecord } from '@interfaces';

import styles from './form-select.module.scss';

interface FormSelectProps extends Omit<FormSelectRecord, '__typename' | 'id'> {
	selected?: string | null;
}

export const FormSelect = ({
	isRequired = false,
	label,
	name,
	options,
	selected,
}: FormSelectProps) => {
	const [selectedValue, setSelectedValue] = useState(selected || '');

	const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedValue(event.currentTarget.value);
	};

	return (
		<div className={styles.wrapper}>
			<div aria-hidden="true" className={styles.label}>
				<span>
					{selectedValue.trim() || (isRequired ? `${label}\u202F*` : label)}
				</span>
				<Svg className={styles.arrow} id="arrow" />
			</div>
			<select
				aria-label={label}
				className={styles.select}
				id={name}
				name={name}
				onChange={onChange}
				required={isRequired}
				value={selectedValue || undefined}
			>
				<option aria-label="Empty" value="" />
				{options.map(({ id, value }) => (
					<option key={id} value={value}>
						{value}
					</option>
				))}
			</select>
		</div>
	);
};
