import { Markdown } from '@components/markdown/Markdown';
import { TextMarquee } from '@components/textMarquee/TextMarquee';
import { Video } from '@components/video/Video';

import { VideoSectionRecord } from '@interfaces';

import styles from './video-section.module.scss';

type VideoSectionProps = VideoSectionRecord;

export const VideoSection = ({
	title,
	hash,
	video,
	captions,
	text,
}: VideoSectionProps) => {
	return (
		<div className={styles['video-section']} id={hash}>
			<Video
				captions={captions}
				hasAutoPlay
				hasControls={false}
				isLoop
				isMuted
				media={video}
			/>
			{title ? (
				<h2 className={styles['video-section__heading']}>
					<TextMarquee text={title} />
				</h2>
			) : null}
			{text ? (
				<Markdown className={styles['video-section__text']} text={text} />
			) : null}
		</div>
	);
};
