import { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Button } from '@components/button/Button';
import { FormInput } from '@components/formInput/FormInput';
import { FormSelect } from '@components/formSelect/FormSelect';
import { FormTextarea } from '@components/formTextarea/FormTextarea';
import { Markdown } from '@components/markdown/Markdown';
import { SplitHeading } from '@components/splitHeading/SplitHeading';

import { handleError } from '@common/utils/handleError';
import { RentalsFormRecord } from '@interfaces';

import styles from './rentals-form.module.scss';

interface RentalsFormProps extends RentalsFormRecord {
	className?: string;
}

export const RentalsForm = ({
	className,
	title,
	text,
	hash,
	firstNameLabel,
	lastNameLabel,
	emailAddressLabel,
	experienceTypeLabel,
	experienceTypeOptions,
	howDidYouHearAboutUsLabel,
	howDidYouHearAboutUsOptions,
	budgetLabel,
	budgetOptions,
	messageLabel,
	submitButtonLabel,
	thankYouMessage,
}: RentalsFormProps) => {
	const [isFormValid, setIsFormValid] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const router = useRouter();

	const handleChange = (event: React.FormEvent<HTMLFormElement>) => {
		setIsFormValid(event.currentTarget.checkValidity());
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);

		const formElement = event.currentTarget;
		const formData = new FormData(formElement);

		// Combine these three fields in the `additional_information` field
		const budgetValue = String(formData.get('budget'));
		const messageValue = String(formData.get('message'));
		const howDidYouHearAboutUsValue = String(
			formData.get('how_did_you_hear_about_us'),
		);

		const additionalInformation = [
			`${budgetLabel}\n\n${budgetValue.length ? budgetValue : '-'}`,
			`${messageLabel}\n\n${messageValue.length ? messageValue : '-'}`,
			`${howDidYouHearAboutUsLabel}\n\n${howDidYouHearAboutUsValue.length ? howDidYouHearAboutUsValue : '-'}`,
		];

		const leadData = {
			first_name: formData.get('first_name'),
			last_name: formData.get('last_name'),
			email_address: formData.get('email_address'),
			guest_count: formData.get('guest_count'),
			event_description: formData.get('experience_type'),
			additional_information: additionalInformation.join('\n\n'),
		};

		fetch('/api/create-lead', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(leadData),
		})
			.then(async (response) => {
				const { message }: { message: string } = await response.json();

				if (response.status !== 200) {
					setErrorMessage(message);
				} else {
					// Redirect to the ‘thank you’ page
					router.push(formElement.action);
				}
			})
			.catch((error: Error) => {
				handleError(error.message);
				setErrorMessage('We’re sorry, something went wrong...');
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<form
			action={`/thank-you/${thankYouMessage.identifier}`}
			className={classNames(className, styles.form)}
			id={hash}
			onChange={handleChange}
			onSubmit={handleSubmit}
		>
			<header className={styles.header}>
				<SplitHeading className={styles.title} heading={title} tag="h2" />
				<Markdown className={styles.text} text={text} />
			</header>
			<div className={styles.fields}>
				{errorMessage ? (
					<Markdown className={styles.error} text={errorMessage} />
				) : null}
				<FormInput
					autoComplete="given-name"
					isRequired
					kind="text"
					label={firstNameLabel}
					name="first_name"
				/>
				<FormInput
					autoComplete="family-name"
					isRequired
					kind="text"
					label={lastNameLabel}
					name="last_name"
				/>
				<FormInput
					autoComplete="email"
					isRequired
					kind="email"
					label={emailAddressLabel}
					name="email_address"
				/>
				{/* This fields acts as a honeypot field to prevent spam submissions: */}
				<FormInput
					className={styles['guest-count']}
					kind="number"
					label="Guest count"
					name="guest_count"
				/>
				<FormSelect
					label={experienceTypeLabel}
					name="experience_type"
					options={experienceTypeOptions}
				/>
				<FormSelect label={budgetLabel} name="budget" options={budgetOptions} />
				<FormSelect
					label={howDidYouHearAboutUsLabel}
					name="how_did_you_hear_about_us"
					options={howDidYouHearAboutUsOptions}
				/>
				<FormTextarea label={messageLabel} name="message" />
				<Button disabled={!isFormValid || isSubmitting} type="submit">
					{submitButtonLabel}
				</Button>
			</div>
		</form>
	);
};
