import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { JobListRecord } from '@interfaces';

import styles from './job-list.module.scss';
import { JobPosition } from './JobPosition';

type JobListProps = JobListRecord;

export const JobList = ({ title, hash, positions }: JobListProps) => {
	if (!positions.length) return null;

	return (
		<section className={styles['job-list']} id={hash}>
			<header className={styles['job-list__header']}>
				<h2 className={styles['job-list__heading']}>{title}</h2>
				<Svg
					className={styles['job-list__hand-drawn-stroke']}
					id="hand-drawn-stroke-2"
				/>
			</header>
			<ul
				className={classNames(
					styles['job-list__position-list'],
					'list-unstyled',
				)}
			>
				{positions.map((position) => (
					<li className={styles['job-list__position']} key={position.id}>
						<JobPosition {...position} />
					</li>
				))}
			</ul>
		</section>
	);
};
