import classNames from 'classnames';

import { CallToActionLink } from '@components/callToAction/CallToActionLink';
import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';
import { SplitHeading } from '@components/splitHeading/SplitHeading';
import { Svg } from '@components/svg/Svg';

import { PageHeaderRecord } from '@interfaces';

import styles from './page-header.module.scss';

type PageHeaderProps = PageHeaderRecord;

export const PageHeader = ({
	isAlternate,
	heading,
	clippedImage,
	text,
	callToActionLink,
}: PageHeaderProps) => {
	const callToAction = callToActionLink.length ? (
		<div className={styles['call-to-action']}>
			<CallToActionLink
				className={styles['call-to-action-link']}
				{...callToActionLink[0]}
			/>
		</div>
	) : null;

	return (
		<header
			className={classNames(styles['page-header'], {
				[styles['is-alternate']]: isAlternate,
			})}
		>
			<SplitHeading className={styles.heading} heading={heading} tag="h2" />
			<div className={styles['image-wrapper']}>
				<ClippedImage {...clippedImage} />
				{callToAction ? null : (
					<Svg
						className={styles['hand-drawn-arrow']}
						id={isAlternate ? 'hand-drawn-arrow-1' : 'hand-drawn-arrow-3'}
					/>
				)}
			</div>
			{callToAction}
			<Markdown className={styles.text} text={text} />
			{/*
				For the alternate version we need to render the call to action below the text
				on larger screens. The layout is using floats and thus we need a second copy
				to achieve this. This also helps us to maintain tab order if links are present
				in the text.
			 */}
			{isAlternate ? callToAction : null}
		</header>
	);
};
