import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AccordionItemRecord } from '@interfaces';

import styles from './accordion.module.scss';
import { AccordionItem } from './AccordionItem';

interface AccordionProps {
	items: AccordionItemRecord[];
	buttonWrapper?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	hasArrow?: boolean;
	className?: string;
	isOneItemPerView?: boolean;
	isFirstItemExpanded?: boolean;
	contentId?: string;
}

export const Accordion = ({
	items,
	buttonWrapper,
	hasArrow = true,
	className,
	isOneItemPerView,
	isFirstItemExpanded,
	contentId,
}: AccordionProps) => {
	const initialItemIndex = isFirstItemExpanded ? 0 : null;
	const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(
		initialItemIndex,
	);

	useEffect(() => {
		if (initialItemIndex !== null) {
			setCurrentItemIndex(0);
		}
	}, [contentId]);

	const updateCurrentItemIndex = (index: number) => {
		setCurrentItemIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	return (
		<div className={classNames(className, styles.accordion)}>
			{items.map((item, index) => {
				return (
					<AccordionItem
						buttonWrapper={buttonWrapper}
						className={styles.accordion__item}
						hasArrow={hasArrow}
						isCurrentItem={currentItemIndex === index}
						isOneItemPerView={isOneItemPerView}
						key={item.id}
						onCurrentItemChange={() => updateCurrentItemIndex(index)}
						{...item}
					/>
				);
			})}
		</div>
	);
};
