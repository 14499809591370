import { TextSectionRecord } from '@interfaces';

import styles from './text-section.module.scss';

type TextSectionProps = TextSectionRecord;

export const TextSection = ({ text }: TextSectionProps) => {
	return (
		<div className={styles['text-section']}>
			<p>{text}</p>
		</div>
	);
};
