import { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './spotify-playlist-embed.module.scss';

interface SpotifyPlaylistEmbedProps {
	className?: string;
	title: string;
	url: string;
}

const getEmbedSrc = (url: string): string | null => {
	const urlObject = new URL(url);

	if (urlObject.host !== 'open.spotify.com') {
		return null;
	}

	if (urlObject.pathname.startsWith('/embed')) {
		return urlObject.href;
	}

	return `${urlObject.origin}/embed${urlObject.pathname}?theme=0`;
};

export const SpotifyPlaylistEmbed = ({
	className,
	title,
	url,
}: SpotifyPlaylistEmbedProps) => {
	const [isLazyLoaded, setIsLazyLoaded] = useState(true);
	const src = getEmbedSrc(url);

	useEffect(() => {
		const isIntersectionObserverSupported = 'IntersectionObserver' in window;

		if (!isIntersectionObserverSupported) {
			setIsLazyLoaded(false);
		}
	}, []);

	if (src) {
		return (
			<div className={classNames(className, styles['spotify-playlist-embed'])}>
				<iframe
					allow="encrypted-media"
					className={styles['spotify-playlist-embed__iframe']}
					data-src={isLazyLoaded ? src : undefined}
					src={isLazyLoaded ? undefined : src}
					title={`${title} playlist`}
				/>
			</div>
		);
	}

	return null;
};
