import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';
import { Svg } from '@components/svg/Svg';

import { MissionStatementRecord } from '@interfaces';

import styles from './mission-statement.module.scss';

const getSvgName = (index: number) => {
	switch (index) {
		case 1:
			return 'hand-drawn-arrow-1';
		case 2:
			return 'hand-drawn-star';
		default:
			return 'hand-drawn-stroke-1';
	}
};

type MissionStatementProps = MissionStatementRecord;

export const MissionStatement = ({ blocks }: MissionStatementProps) => {
	return (
		<div className={styles['mission-statement']}>
			{blocks.map(({ id, heading, text }, index) => {
				const svgName = getSvgName(index);

				return (
					<div className={styles['mission-statement__section']} key={id}>
						<header className={styles['mission-statement__header']}>
							<h2 className={styles['mission-statement__heading']}>
								{heading}
							</h2>
							<Svg
								className={classNames(
									styles['mission-statement__svg'],
									styles[`mission-statement__svg--${svgName.toLowerCase()}`],
								)}
								id={svgName}
							/>
						</header>
						<Markdown
							className={styles['mission-statement__text']}
							text={text}
						/>
					</div>
				);
			})}
		</div>
	);
};
