import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';
import { Svg } from '@components/svg/Svg';

import { BlockQuoteRecord } from '@interfaces';

import styles from './block-quote.module.scss';

interface BlockQuoteProps extends Pick<BlockQuoteRecord, 'quote' | 'quotee'> {
	className?: string;
}

export const BlockQuote = ({ quote, quotee, className }: BlockQuoteProps) => (
	<div className={classNames(className, styles['block-quote'])}>
		<Svg
			aria-hidden="true"
			className={styles['block-quote__arrow']}
			id="hand-drawn-arrow-2"
		/>
		<figure>
			<blockquote>
				<Markdown text={quote} />
			</blockquote>
			<figcaption>{quotee}</figcaption>
		</figure>
	</div>
);
