import Link from 'next/link';
import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';
import { MembershipIllustration } from '@components/membershipIllustration/MembershipIllustration';
import { SplitHeading } from '@components/splitHeading/SplitHeading';

import { getMembershipUpsellLinkHref } from '@common/utils/membership';
import { useUser } from '@context/UserContext';
import { MEMBERSHIP_TEASER_LINK_TEXT } from '@data/constants';
import {
	MembershipUpsellConfigRecord,
	MembershipUpsellSectionRecord,
	SupabaseProductRecordWithPrices,
} from '@interfaces';

import styles from './membership-upsell-section.module.scss';

interface MembershipUpsellSectionProps extends MembershipUpsellSectionRecord {
	membershipProducts: SupabaseProductRecordWithPrices[] | null;
	membershipUpsellConfig: MembershipUpsellConfigRecord;
}

export const MembershipUpsellSection = ({
	heading,
	text,
	membership,
	membershipProducts,
	membershipUpsellConfig,
}: MembershipUpsellSectionProps) => {
	const { subscription } = useUser();

	if (subscription) {
		return null;
	}

	const linkHref = getMembershipUpsellLinkHref(
		membership,
		membershipUpsellConfig,
		membershipProducts,
	);

	if (!linkHref) {
		return null;
	}

	return (
		<section className={classNames(styles['membership-upsell-section'])}>
			<SplitHeading
				className={styles['membership-upsell-section__heading']}
				heading={heading || membershipUpsellConfig.heading}
				tag="h2"
			/>
			<div className={styles['membership-upsell-section__content']}>
				<div className={styles['membership-upsell-section__illustration']}>
					<MembershipIllustration
						sizes="(min-width: 75em) 36.7vw, 73.3vw"
						type="upsell"
					/>
				</div>
				<div className={styles['membership-upsell-section__info']}>
					<Markdown text={text || membershipUpsellConfig.text} />
					<Link
						className={classNames(
							styles['membership-upsell-section__button-link'],
							'button',
						)}
						href={linkHref}
					>
						{MEMBERSHIP_TEASER_LINK_TEXT}
					</Link>
				</div>
			</div>
		</section>
	);
};
