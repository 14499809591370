import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { getMailchimpUrl } from '@common/utils/mailchimp';
import { NewsletterFormRecord, NewsletterSignUpLocation } from '@interfaces';

interface NewsletterFormProps extends NewsletterFormRecord {
	className?: string;
	location: NewsletterSignUpLocation;
}

export const NewsletterForm = ({
	emailInputLabel,
	mailchimpListId,
	mailchimpFormLocationFieldTag,
	mailchimpTags,
	className,
	location,
}: NewsletterFormProps) => {
	const mailchimpFormUrl = getMailchimpUrl({
		mailchimpListId,
		mailchimpFormLocationFieldTag,
		mailchimpTags,
		location,
	});

	return (
		<form
			action={mailchimpFormUrl}
			className={classNames(className, 'newsletter-form')}
			data-list-id={mailchimpListId}
			data-location={location}
			method="post"
		>
			<input
				aria-label={emailInputLabel}
				autoComplete="email"
				className="newsletter-form__input"
				name="EMAIL"
				placeholder={emailInputLabel}
				required
				type="email"
			/>
			<button
				aria-label="Submit"
				className="newsletter-form__submit"
				name="subscribe"
				type="submit"
			>
				<Svg className="newsletter-form__arrow" id="arrow" />
			</button>
		</form>
	);
};
