import classNames from 'classnames';

import { Accordion } from '@components/accordion/Accordion';
import { Markdown } from '@components/markdown/Markdown';
import { Svg } from '@components/svg/Svg';

import { FaqSectionRecord } from '@interfaces';

import styles from './faq-section.module.scss';

interface FaqSectionProps extends FaqSectionRecord {
	className?: string;
	isArrowDecorationVisible?: boolean;
}

export const FaqSection = ({
	className,
	isArrowDecorationVisible = true,
	title,
	topics,
}: FaqSectionProps) => {
	return (
		<section className={classNames(className, styles['faq-section'])}>
			<header className={styles['faq-section__header']}>
				<h2 className={styles['faq-section__heading']} id="faq">
					{title}
				</h2>
				{isArrowDecorationVisible ? (
					<Svg
						className={styles['faq-section__hand-drawn-arrow']}
						id="hand-drawn-arrow-1"
					/>
				) : null}
			</header>
			{topics.map((topic) => {
				// prepare summaries and panels for the accordion component
				const accordionItems = topic.faqs.map(({ id, answer, question }) => ({
					id,
					summary: <Markdown text={question} />,
					panel: <Markdown text={answer} />,
				}));

				return (
					<div className={styles['faq-section__topic']} key={topic.id}>
						{/* Only show the subheading if there’s more than 1 topic */}
						{topics.length > 1 ? (
							<h3 className={styles['faq-section__subheading']}>
								{topic.title}
							</h3>
						) : null}
						<Accordion items={accordionItems} />
					</div>
				);
			})}
		</section>
	);
};
