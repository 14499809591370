import { Switcher } from '@components/switcher/Switcher';

import { PressAwardSectionRecord } from '@interfaces';

import { AwardList } from './AwardList';
import styles from './press-award-section.module.scss';
import { PressQuote } from './PressQuote';

type PressAwardsSectionProps = PressAwardSectionRecord;

export const PressAwardSection = ({
	title,
	hash,
	awards,
	pressQuotes,
}: PressAwardsSectionProps) => {
	if (!pressQuotes.length || !awards.length) {
		return null;
	}

	const switcherItems = pressQuotes.map((pressQuote) => {
		return {
			key: pressQuote.id,
			node: (
				<PressQuote {...pressQuote} sizes="(min-width: 56.25em) 50vw, 100vw" />
			),
		};
	});

	return (
		<section id={hash}>
			<h2 className={styles.heading}>{title}</h2>
			<Switcher items={switcherItems} />
			<AwardList awards={awards} className={styles.awards} />
		</section>
	);
};
