import { Markdown } from '@components/markdown/Markdown';
import { SpotifyPlaylistEmbed } from '@components/spotifyPlaylistEmbed/SpotifyPlaylistEmbed';
import { TextMarquee } from '@components/textMarquee/TextMarquee';

import { PlaylistSectionRecord } from '@interfaces';

import styles from './playlist-section.module.scss';

type PlaylistSectionProps = PlaylistSectionRecord;

export const PlaylistSection = ({
	title,
	backgroundColor,
	image,
	spotifyPlaylistUrl,
	text,
}: PlaylistSectionProps) => {
	const { responsiveImage } = image;

	const style = backgroundColor
		? ({ '--background-color': backgroundColor.hex } as React.CSSProperties)
		: undefined;

	const textWrapper = (
		<div className={styles['playlist-section__text-wrapper']}>
			<Markdown className={styles['playlist-section__text']} text={text} />
		</div>
	);

	return (
		<article className={styles['playlist-section']} style={style}>
			<h2 className={styles['playlist-section__heading']}>
				<TextMarquee text={title} />
			</h2>
			<div className={styles['playlist-section__content']}>
				<figure className={styles['playlist-section__image-wrapper']}>
					<img
						alt={image.alt || ''}
						className={styles['playlist-section__image']}
						height={image.height}
						loading="lazy"
						sizes="36vw"
						src={responsiveImage.src}
						srcSet={responsiveImage.srcSet}
						width={image.width}
					/>
				</figure>
				{textWrapper}
				<div className={styles['playlist-section__playlist-wrapper']}>
					<SpotifyPlaylistEmbed
						className={styles['playlist-section__playlist']}
						title={title}
						url={spotifyPlaylistUrl}
					/>
				</div>
				{textWrapper}
			</div>
		</article>
	);
};
