import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';

import { InfoBlockSectionRecord } from '@interfaces';

import styles from './info-block-section.module.scss';

type InfoBlockSectionProps = InfoBlockSectionRecord;

export const InfoBlockSection = ({
	primaryClippedImage,
	secondaryClippedImage,
	blocks,
}: InfoBlockSectionProps) => {
	return (
		<div className={styles['info-block-section']}>
			<div className={styles['info-block-section__images']}>
				<ClippedImage
					className={styles['info-block-section__primary-image']}
					{...primaryClippedImage}
					sizes="(min-width: 56.25em) 52vw, 100vw"
				/>
				{secondaryClippedImage ? (
					<ClippedImage
						className={styles['info-block-section__secondary-image']}
						{...secondaryClippedImage}
						sizes="(min-width: 56.25em) 28.08vw, 54vw"
					/>
				) : null}
			</div>
			<div className={styles['info-block-section__blocks']}>
				{blocks.map(({ id, heading, text }) => (
					<article className={styles['info-block-section__block']} key={id}>
						<h2 className={styles['info-block-section__heading']}>{heading}</h2>
						<Markdown
							className={styles['info-block-section__text']}
							text={text}
						/>
					</article>
				))}
			</div>
		</div>
	);
};
