import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useSetInterval } from '@hooks/useSetInterval';

import styles from './switcher.module.scss';

type SwitcherItem = {
	key: string;
	node: React.ReactNode;
};

interface SwitcherProps {
	className?: string;
	items: SwitcherItem[];
	intervalDelay?: number;
	startDelay?: number;
}

export const Switcher = ({
	className,
	items,
	startDelay = 0,
	intervalDelay = 5000,
}: SwitcherProps) => {
	const [visibleIndex, setVisibleIndex] = useState(0);
	const timeoutRef = useRef<number>();

	useSetInterval(() => {
		timeoutRef.current = window.setTimeout(() => {
			setVisibleIndex(visibleIndex === items.length - 1 ? 0 : visibleIndex + 1);
		}, startDelay);
	}, intervalDelay);

	useEffect(() => {
		return () => window.clearTimeout(timeoutRef.current);
	}, []);

	return (
		<ul className={classNames(className, styles.switcher, 'list-unstyled')}>
			{items.map(({ key, node }, index) => (
				<li
					aria-hidden={visibleIndex !== index}
					className={styles.item}
					key={key}
				>
					{node}
				</li>
			))}
		</ul>
	);
};
