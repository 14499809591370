import { CallToActionLink } from '@components/callToAction/CallToActionLink';
import { ListingThumbnail } from '@components/programme/ListingThumbnail';
import { Svg } from '@components/svg/Svg';
import { Switcher } from '@components/switcher/Switcher';

import { DiceEvent, UpcomingEventSectionRecord } from '@interfaces';

import styles from './just-announced-event-section.module.scss';

interface JustAnnouncedEventSectionProps extends UpcomingEventSectionRecord {
	events?: DiceEvent[];
}

export const JustAnnouncedEventSection = ({
	title,
	events = [],
	linkLabel,
}: JustAnnouncedEventSectionProps) => {
	if (!events.length) {
		return null;
	}

	const oddSwitcherItems = events
		.filter((_, index) => index % 2 === 1)
		.map((event) => {
			return {
				key: String(event.id),
				node: (
					<ListingThumbnail
						event={event}
						headingTag="h3"
						sizes="(min-width: 75em) 31.16vw, (min-width: 56.25em) 34.96em, 100vw"
					/>
				),
			};
		});

	const evenSwitcherItems = events
		.filter((_, index) => index % 2 === 0)
		.map((event) => {
			return {
				key: String(event.id),
				node: (
					<ListingThumbnail
						event={event}
						headingTag="h3"
						sizes="(min-width: 75em) 50.84vw, (min-width: 56.25em) 57.04vw, 100vw"
					/>
				),
			};
		});

	return (
		<div className={styles['just-announced-event-section']}>
			<h2 className={styles['just-announced-event-section__heading']}>
				{title}
			</h2>
			<div className={styles['just-announced-event-section__content']}>
				<Switcher
					className={styles['just-announced-event-section__events']}
					items={oddSwitcherItems}
				/>
				<Switcher
					className={styles['just-announced-event-section__events']}
					items={evenSwitcherItems}
					startDelay={2500}
				/>
				<CallToActionLink
					className={
						styles['just-announced-event-section__call-to-action-link']
					}
					label={linkLabel}
					url="/events"
				/>
			</div>
			<Svg
				className={styles['just-announced-event-section__hand-drawn-arrow']}
				id="hand-drawn-arrow-1"
			/>
		</div>
	);
};
