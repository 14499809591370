import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { AccordionItemRecord } from '@interfaces';

import styles from './accordion-item.module.scss';

interface AccordionItemProps extends AccordionItemRecord {
	buttonWrapper?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	hasArrow?: boolean;
	className?: string;
	isCurrentItem?: boolean;
	isOneItemPerView?: boolean;
	onCurrentItemChange: () => void;
}

export const AccordionItem = ({
	id,
	isCurrentItem,
	isDisabled = false,
	isInitiallyExpanded = false,
	isOneItemPerView,
	summary,
	panel,
	className,
	buttonWrapper,
	hasArrow,
	onCurrentItemChange,
}: AccordionItemProps) => {
	// Allow isInitiallyExpanded to override isCurrentItem
	const [isPanelContentVisible, setIsPanelContentVisible] = useState(
		isOneItemPerView ? isCurrentItem : isInitiallyExpanded,
	);
	const accordionItemElementRef = useRef<HTMLElement>(null);

	const onClick = () => {
		const accordionItemElement = accordionItemElementRef.current;

		if (accordionItemElement) {
			setIsPanelContentVisible(!isPanelContentVisible);
		}

		if (isOneItemPerView) {
			onCurrentItemChange();
		}
	};

	useEffect(() => {
		setIsPanelContentVisible(isCurrentItem);
	}, [isCurrentItem]);

	const button = (
		<button
			aria-controls={`accordion-item-panel-${id}`}
			aria-expanded={isPanelContentVisible}
			className={styles.button}
			disabled={isDisabled}
			id={`accordion-item-button-${id}`}
			onClick={onClick}
			type="button"
		>
			{summary}
			{hasArrow ? <Svg className={styles.arrow} id="arrow" /> : null}
		</button>
	);

	// optionally wrap the button in a heading
	const ButtonWrapper = buttonWrapper as keyof JSX.IntrinsicElements;

	return (
		<article
			className={classNames(className, styles.item, {
				[styles['is-expanded']]: isPanelContentVisible,
			})}
			data-is-expanded={isPanelContentVisible}
			ref={accordionItemElementRef}
		>
			{ButtonWrapper ? (
				<ButtonWrapper className={styles.heading}>{button}</ButtonWrapper>
			) : (
				button
			)}
			<div
				aria-hidden={!isPanelContentVisible}
				aria-labelledby={`accordion-item-button-${id}`}
				className={styles.panel}
				id={`accordion-item-panel-${id}`}
				role="region"
			>
				{panel}
			</div>
		</article>
	);
};
