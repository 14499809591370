import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';
import { Testimonial } from '@components/testimonial/Testimonial';

import { TestimonialSectionRecord } from '@interfaces';

import styles from './testimonial-section.module.scss';

type TestimonialSectionProps = TestimonialSectionRecord;

export const TestimonialSection = ({
	title,
	testimonials,
}: TestimonialSectionProps) => {
	return (
		<section className={styles.section}>
			<header className={styles.header}>
				<h2 className={styles.title}>{title}</h2>
				<Svg className={styles.arrow} id="hand-drawn-arrow-2" />
			</header>
			<ul className={classNames(styles.list, 'list-unstyled')}>
				{testimonials.map((testimonial) => (
					<li key={testimonial.id}>
						<Testimonial {...testimonial} />
					</li>
				))}
			</ul>
		</section>
	);
};
