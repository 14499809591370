import classNames from 'classnames';

import { PressAwardSectionRecord } from '@interfaces';

import styles from './award-list.module.scss';

interface AwardListProps extends Pick<PressAwardSectionRecord, 'awards'> {
	className?: string;
}

export const AwardList = ({ awards, className }: AwardListProps) => {
	if (awards.length) {
		return (
			<ul className={classNames(className, styles.list, 'list-unstyled')}>
				{awards.map(({ id, logo, organization, description, year }) => {
					const logoStyle = {
						'--award-logo-aspect-ratio': logo.width / logo.height,
					} as React.CSSProperties;

					return (
						<li key={id}>
							<article>
								<img
									alt={logo.alt || ''}
									className={styles.logo}
									height={logo.height}
									loading="lazy"
									src={logo.url}
									style={logoStyle}
									width={logo.width}
								/>
								<h3 className={styles.heading}>{organization}</h3>
								<p>
									{description} ({year})
								</p>
							</article>
						</li>
					);
				})}
			</ul>
		);
	}

	return null;
};
