import classnames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { ShowcaseRecord } from '@interfaces';

import styles from './showcase.module.scss';
import { ShowcaseItem } from './ShowcaseItem';

interface ShowcaseProps extends ShowcaseRecord {
	className?: string;
}

export const Showcase = ({
	title,
	items,
	className,
	isHandDrawnElementVisible,
}: ShowcaseProps) => {
	return (
		<div className={classnames(className, styles.showcase)}>
			<header className={styles.header}>
				<h2 className={styles.heading}>{title}</h2>
				{isHandDrawnElementVisible ? (
					<Svg className={styles['hand-drawn-star']} id="hand-drawn-star" />
				) : null}
			</header>
			<div className={styles.items}>
				{items.map((item, index) => {
					return (
						<ShowcaseItem
							isInitiallyExpanded={index === 0}
							key={item.id}
							{...item}
						/>
					);
				})}
			</div>
		</div>
	);
};
