import { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './countdown.module.scss';

const paddedString = (value: number) =>
	Math.floor(value).toString().padStart(2, '0');

const getRemaining = (endTime: string) => {
	const total = Date.parse(endTime) - Date.now();
	const seconds = paddedString((total / 1000) % 60);
	const minutes = paddedString((total / 1000 / 60) % 60);
	const hours = paddedString((total / (1000 * 60 * 60)) % 24);
	const days = paddedString(total / (1000 * 60 * 60 * 24));

	if (total <= 0) {
		return { isPast: true, time: '00:00:00:00' };
	}

	return {
		isPast: false,
		time: `${days}:${hours}:${minutes}:${seconds}`,
	};
};

interface CountdownProps {
	to: string;
	className?: string;
}

export const Countdown = ({ to, className }: CountdownProps) => {
	const initial = getRemaining(to);
	const [remaining, setRemaining] = useState(initial);

	useEffect(() => {
		let timeInterval: ReturnType<typeof setInterval>;

		const setTime = () => {
			const { isPast, time } = getRemaining(to);

			if (isPast && timeInterval) {
				clearInterval(timeInterval);
			} else {
				setRemaining({ isPast, time });
			}
		};

		timeInterval = setInterval(setTime, 1000);
		setTime();

		return () => {
			clearInterval(timeInterval);
		};
	}, []);

	return (
		<p
			className={classNames(className, {
				[styles['is-hidden']]: remaining.isPast,
			})}
		>
			<time dateTime={to} suppressHydrationWarning>
				{remaining.time}
			</time>
		</p>
	);
};
