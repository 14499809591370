import { HeaderSectionRecord } from '@interfaces';

import styles from './header-section.module.scss';

type HeaderSectionProps = HeaderSectionRecord;

export const HeaderSection = ({ text, size }: HeaderSectionProps) => {
	const Header = size === 'T1' ? 'h1' : ('h2' as keyof JSX.IntrinsicElements);
	return (
		<div className={styles['header-section']}>
			<Header>{text}</Header>
		</div>
	);
};
