import { FormInputRecord } from '@interfaces';

interface FormInputProps extends Omit<FormInputRecord, '__typename' | 'id'> {
	className?: string;
}

export const FormInput = ({
	autoComplete,
	className,
	isRequired = false,
	kind,
	label,
	name,
}: FormInputProps) => {
	// We’re using `kind` instead of `type` the latter is a reserved name in Dato.
	return (
		<input
			aria-label={label}
			autoComplete={autoComplete}
			className={className}
			name={name}
			placeholder={isRequired ? `${label}\u202F*` : label}
			required={isRequired}
			type={kind}
		/>
	);
};
