import { Markdown } from '@components/markdown/Markdown';

import { TestimonialRecord } from '@interfaces';

import styles from './testimonial.module.scss';

type TestimonialProps = TestimonialRecord;

export const Testimonial = ({ name, role, image, quote }: TestimonialProps) => {
	return (
		<article>
			<header className={styles.header}>
				<img
					alt={image.alt || ''}
					height={image.height}
					loading="lazy"
					sizes="(min-width: 56.25em) 33.3333vw, 100vw"
					src={image.responsiveImage.src}
					srcSet={image.responsiveImage.srcSet}
					width={image.width}
				/>
				<h3 className={styles.name}>{name}</h3>
				<p>{role}</p>
			</header>
			<Markdown text={quote} />
		</article>
	);
};
