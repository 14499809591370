import classNames from 'classnames';

import { Programme } from '@components/programme/Programme';

import {
	EventCollectionRecord,
	MembershipUpsellConfigRecord,
	PaginationData,
	ProgrammeLabels,
	SupabaseProductRecordWithPrices,
} from '@interfaces';

import styles from './event-collection.module.scss';

type EventCollectionProps = Partial<EventCollectionRecord> & {
	className?: string;
	programmeLabels?: ProgrammeLabels;
	serverNow?: number;
	membershipProducts?: SupabaseProductRecordWithPrices[] | null;
	membershipUpsellConfig?: MembershipUpsellConfigRecord;
	paginationData?: PaginationData;
	isEventsDataLoading?: boolean;
};

export const EventCollection = ({
	className,
	title,
	showTitle,
	description,
	events,
	view,
	programmeLabels,
	showTicketAnchors,
	diceAccessCode,
	paginationData,
	isEventsDataLoading,
	serverNow,
	includeMembershipTeaser,
	membershipTeaserMembership,
	membershipTeaserHeading,
	membershipTeaserText,
	membershipProducts,
	membershipUpsellConfig,
}: EventCollectionProps) => {
	return (
		<div className={classNames(className, styles['event-collection'])}>
			<header className={styles['event-collection__header']}>
				{showTitle && (
					<h2 className={styles['event-collection__heading']}>{title}</h2>
				)}
				{!!description && (
					<p className={styles['event-collection__description']}>
						{description}
					</p>
				)}
			</header>
			{events?.length ? (
				<Programme
					className={styles['event-collection__programme']}
					diceAccessCode={diceAccessCode}
					events={events}
					includeMembershipTeaser={includeMembershipTeaser}
					isLoading={isEventsDataLoading}
					membershipProducts={membershipProducts}
					membershipTeaserHeading={membershipTeaserHeading}
					membershipTeaserMembership={membershipTeaserMembership}
					membershipTeaserText={membershipTeaserText}
					membershipUpsellConfig={membershipUpsellConfig}
					paginationData={paginationData}
					previewHeadingTag="h3"
					programmeLabels={programmeLabels}
					serverNow={serverNow}
					showTicketAnchors={showTicketAnchors}
					view={view}
				/>
			) : null}
		</div>
	);
};
